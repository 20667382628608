import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-scroll-switcher',
  templateUrl: './scroll-switcher.component.html',
  styleUrls: ['./scroll-switcher.component.scss']
})
export class ScrollSwitcherComponent implements OnInit {

  @Input() options = [
    {
      text: 'Ru',
      value: 'ru'
    },
    {
      text: 'En',
      value: 'en'
    },
    {
      text: 'Fr',
      value: 'fr'
    },
    {
      text: 'UK',
      value: 'uk'
    }
  ];
  @Input() direction: 'vertical' | 'horizontal' = 'horizontal';

  @ViewChild('switcherWrapper', { static: false }) switcherWrapperEl: ElementRef;

  selectedOptionIndex$ = new BehaviorSubject(0);

  selectedOption$ = this.selectedOptionIndex$.asObservable()
    .pipe(
      map(index => this.options[index]),
      distinctUntilChanged(),
      tap(console.log)
    );

  constructor() { }

  ngOnInit(): void {
  }

  scrollHandler(evt): void {
    // if (Math.floor(document.querySelector('#wrapper').scrollTop) > currentScrollTop && currentIndex < this.options.length) {
    //   this.selectedOptionIndex$.next(currentIndex + 1);
    // } else if (currentIndex > 0) {
    //   this.selectedOptionIndex$.next(currentIndex - 1);
    // } else {
    //   return;
    // }
    this.selectedOptionIndex$.next(Math.floor(document.querySelector('#wrapper').scrollTop / 24));
  }
}

export function debounce(delay: number = 300): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = (...args) => {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
