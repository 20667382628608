<svg
  role="img"
  aria-hidden="false"
  class="svelte-iqs3k3"
  [attr.fill]="color"
>
  <g>
    <path
      d="M0 0V32H32V0H0ZM9.4053 12.7438L15.088 16L9.4053 19.287V12.7438ZM9.4053 24.8503V21.6468L19.1842 16L9.4053 10.3532V7.17166L24.6955 16L9.4053 24.8503Z"
    ></path>
  </g>
</svg>
