<ng-container *ngIf="direction === 'vertical'">
  <div
    *init="{
    selectedOption: selectedOption$ | async
  } as params"
    (scroll)="scrollHandler($event)"
    id="wrapper"
    class="scroll-switcher_wrapper"
  >
    <p class="scroll-switcher__option" *ngFor="let option of options">
      {{ option.text }}
    </p>
  </div>
</ng-container>

<ng-container *ngIf="direction === 'horizontal'">
  <div class="horizontal-scroll-switcher">
    <div *ngFor="let item of options" class="horizontal-scroll-switcher__item">{{ item.text }}</div>
  </div>
</ng-container>
