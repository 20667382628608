import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsListComponent implements OnInit {

  @Input() tags;
  @Input() selectedTags;
  @Input() isClickable = true;

  @Output() clickTag = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
