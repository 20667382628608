import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollSwitcherComponent } from './scroll-switcher/scroll-switcher.component';
import {NgxfModule} from '@ngxf/platform';
import {IconsModule} from './icons/icons.module';
import { TagsListComponent } from './tags-list/tags-list.component';
import { CloseButtonComponent } from './buttons/close-button/close-button.component';
import { MenuBurgerComponent } from './buttons/menu-burger/menu-burger.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [ScrollSwitcherComponent, TagsListComponent, CloseButtonComponent, MenuBurgerComponent],
  imports: [
    CommonModule,
    NgxfModule,
    IconsModule,
    FormsModule
  ],
  exports: [ScrollSwitcherComponent, TagsListComponent, CloseButtonComponent, MenuBurgerComponent]
})
export class ComponentsModule { }
