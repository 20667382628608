import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GithubIconComponent } from './github/github.component';
import { TwitterIconComponent } from './twitter/twitter.component';
import { LinkedInIconComponent } from './linked-in/linked-in.component';
import { CodeWarsIconComponent } from './code-wars/code-wars.component';
import { ObservableIconComponent } from './observable/observable.component';
import { PluralsightIconComponent } from './pluralsight/pluralsight.component';
import { MediumIconComponent } from './medium/medium.component';
import { MastodonComponent } from './mastodon/mastodon.component';

const ICONS = [
  TwitterIconComponent,
  GithubIconComponent,
  LinkedInIconComponent,
  CodeWarsIconComponent,
  ObservableIconComponent,
  PluralsightIconComponent,
  MediumIconComponent,
  MastodonComponent,
];

@NgModule({
  declarations: [ICONS],
  imports: [CommonModule],
  exports: [ICONS],
})
export class IconsModule {}
