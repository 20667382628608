<div class="tags">
  <button
    *ngFor="let tag of tags"
    class="tags__tag"
    [class.tags__tag--active]="selectedTags?.includes(tag)"
    [disabled]="!isClickable"
    (click)="clickTag.emit(tag)"
  >
    #{{ tag }}
  </button>
</div>
