<input type="checkbox" id="checkbox1" class="checkbox1 visuallyHidden"
       [(ngModel)]="value"
       (ngModelChange)="valueChange.emit($event)"
>
<label for="checkbox1">
  <div class="hamburger hamburger1">
    <span class="bar bar1"></span>
    <span class="bar bar2"></span>
    <span class="bar bar3"></span>
    <span class="bar bar4"></span>
  </div>
</label>
