import {Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-burger',
  templateUrl: './menu-burger.component.html',
  styleUrls: ['./menu-burger.component.scss']
})
export class MenuBurgerComponent implements OnInit {

  @Input() value;

  @Output() valueChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
